import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import moment from "moment/moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { pageStatusChange } from "../../redux/property/PropertySlice";
import PropertyDetailsModel from "./latestProperty/PropertyDetailsModel";

const BoostedProperty = () => {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const { userViewPropertyAll } = useSelector(
    (store) => store.UserViewProperty
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allListing, setAllListing] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [boostProperties, setBoostProperties] = useState([]);
  const [bycity, setBycity] = useState("Hyderabad");
  const [propertySearch, setPropertySearch] = useState([]);
  const [modal, setModal] = useState(false);
  const [singleProperty, setSingleProperty] = useState("");
  const toggle = () => setModal(!modal);

  useEffect(() => {
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/property/boostedpropertybycity/${bycity}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const searchresult = fetchProperty.data.propertybycity;
            setBoostProperties(searchresult);
          }
        } catch (error) {}
      };

      fetchdata();
    } catch (error) {}
  }, []);

  const closePropDetailModal = () => {
    setModal(false);
  };

  const toggleClick = (e, property) => {
    setModal(!modal);
    setSingleProperty(property);
  };

  const verifyLogin = () => {
    toast.success("Please log in to access this content.", {
      position: "top-center",
      autoClose: 1500,
      className: "custom_toast",
    });
  };

  const changeView = () => {};

  return (
    <>
      {boostProperties.length > 0 ? (
        <>
          <div className="recom-property">
            <div className="recom-head">
              <h1>Top Properties</h1>
              {/* <p>Curated especially for you</p> */}
            </div>
            <Link to="">
              <div className="recommended-swiper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={0}
                  freeMode={true}
                  pagination={{
                    clickable: false,
                  }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },

                    480: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },

                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },

                    840: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },

                    1000: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Autoplay, FreeMode, Navigation]}
                  className="mySwiper"
                >
                  {boostProperties
                    .slice(0)
                    .reverse()
                    .map((property, index) => (
                      <SwiperSlide key={index}>
                        {/* <div className="slick-slide-item">
                          <div className="">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap  agent_card">
                                {property.DeskImg.length > 0 ? (
                                  <>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                      className="geodir-category-img_item"
                                    >
                                      <img src={property.DeskImg[0]} alt="" />
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                      className="geodir-category-img_item"
                                    >
                                      <img
                                        src="/assets/images/noimage1.png"
                                        alt=""
                                      />
                                    </Link>
                                  </>
                                )}

                                <div className="agent-card-social fl-wrap">
                                  <ul>
                                    <li>
                                      <Link to="" target="_blank">
                                        <i className="fa fa-star" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="listing-rating">
                                  <span className="re_stars-title price">
                                    ₹ {property.visiblePrice}
                                  </span>
                                </div>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                ></div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                    >
                                      {property.apartment}
                                    </Link>
                                  </h4>
                                  <h5>
                                    <Link to="">{property.locality}</Link>
                                  </h5>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <Link>{property.subCategory}</Link>
                                  <Link>
                                    {moment(property.updatedAt).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </Link>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div> */}
                        <div className="gallery-item" style={{width:"100%"}} key={index}>
                          <div className="">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap">
                                {property.DeskImg.length > 0 ? (
                                  <>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                      className="geodir-category-img_item"
                                    >
                                      <img src={property.DeskImg[0]} alt="" />
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                      className="geodir-category-img_item"
                                    >
                                      <img
                                        src="/assets/images/noimage1.png"
                                        alt=""
                                      />
                                    </Link>
                                  </>
                                )}

                                <div className="geodir-category-location">
                                  <Link
                                    to={`/property-info/${property.apartment}/${property.propertyID}`}
                                    className="single-map-item tolt"
                                  >
                                    <i className="fas fa-map-marker-alt" />
                                    <span>
                                      {property.subCategory +
                                        " , " +
                                        property.state}
                                    </span>
                                  </Link>
                                </div>
                                <ul className="list-single-opt_header_cat">
                                  <li>
                                    <Link to="" className="cat-opt blue-bg">
                                      {property.category === "Sell" ? (
                                        <>Buy</>
                                      ) : (
                                        <>{property.category}</>
                                      )}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="" className="cat-opt color-bg">
                                      {property.subCategory}
                                    </Link>
                                  </li>
                                </ul>

                                {/* <Link
                    to=""
                    className="geodir_save-btn tolt"
                    data-microtip-position="left"
                    data-tooltip="Save"
                  >
                    <span>
                      <i className="fal fa-heart" />
                    </span>
                  </Link>
                  <Link
                    to=""
                    className="compare-btn tolt"
                    data-microtip-position="left"
                    data-tooltip="Compare"
                  >
                    <span>
                      <i className="fal fa-random" />
                    </span>
                  </Link> */}

                                <div className="geodir-category-listing_media-list">
                                  <span>
                                    <i className="fas fa-camera" />{" "}
                                    {property.DeskImg.length}
                                  </span>
                                </div>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <Link
                                  to={`/property-info/${property.apartment}/${property.propertyID}`}
                                >
                                  <h3
                                    className="title-sin_item"
                                    style={{
                                      color: "#000",
                                      fontWeight: "600",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {property.apartment}
                                  </h3>
                                </Link>
                                <div className="geodir-category-content_price">
                                  ₹ {property.visiblePrice}
                                </div>
                                <p
                                  style={{
                                    color: "#4d4d4d",
                                    fontWeight: "400",
                                    marginLeft:"0px"
                                  }}
                                >
                                  {property.unicProperty}
                                </p>
                                <div className="geodir-category-content-details">
                                  <ul>
                                    <li>
                                      <i className="fal fa-bed" />
                                      <span>{property.noofBedrooms}</span>
                                    </li>
                                    <li>
                                      <i className="fal fa-bath" />
                                      <span>{property.noofBathrooms}</span>
                                    </li>
                                    <li>
                                      <i className="fal fa-cube" />
                                      <span>
                                        {property.carpetArea +
                                          " " +
                                          property.carpetAreaMesurmentType}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  {isAuth ? (
                                    <>
                                      {loginData.creadits >= 1 ? (
                                        <>
                                          <Link
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                            className="btn float-btn small-btn color-bg"
                                            style={{ color: "#fff" }}
                                          >
                                            View Phone Number
                                          </Link>
                                          <Link
                                            to=""
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                            className="btn float-btn small-btn color-bg"
                                            style={{ color: "#fff" }}
                                          >
                                            Contact {property.postUserPosition}
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          <Link
                                            className="btn float-btn small-btn color-bg"
                                            style={{ color: "#fff" }}
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            View Phone Number
                                          </Link>
                                          <Link
                                            className="btn float-btn small-btn color-bg"
                                            style={{ color: "#fff" }}
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            Contact {property.postUserPosition}
                                          </Link>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        className="btn float-btn small-btn color-bg"
                                        style={{ color: "#fff" }}
                                        onClick={() => verifyLogin()}
                                      >
                                        View Phone Number
                                      </Link>
                                      <Link
                                        className="btn float-btn small-btn color-bg"
                                        style={{ color: "#fff" }}
                                        onClick={() => verifyLogin()}
                                      >
                                        Contact {property.postUserPosition}
                                      </Link>
                                    </>
                                  )}

                                  <Modal
                                    isOpen={modal}
                                    toggle={toggle}
                                    className="modalmain"
                                    style={{}}
                                  >
                                    <div className="model-wrapper">
                                      <ModalHeader
                                        toggle={toggle}
                                      ></ModalHeader>
                                      <ModalBody>
                                        <PropertyDetailsModel
                                          closePropdet={closePropDetailModal}
                                          propertyprop={singleProperty}
                                          changeView={changeView}
                                        />
                                      </ModalBody>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BoostedProperty;
